<template>
  <AsyncImage
    v-bind="$attrs"
    :src="
      getImageUrl(
        sticker.id,
        'emote',
        sticker.attributes.kind,
        sticker.attributes.fileName,
      )
    "
    class="object-contain rounded-md"
    :class="{
      'h-32 w-32': !size || size === 'normal',
    }"
  ></AsyncImage>
</template>

<script setup lang="ts">
import type {
  EmoteEntity,
  EmoteRelation,
  PopulateRelationship,
} from "~/src/api";

defineProps<{
  sticker: EmoteEntity | PopulateRelationship<EmoteRelation>;
  quality?: "128" | "256" | "512" | "original";
  size?: "normal" | "small" | "large";
}>();

const { getImageUrl } = useMediaLink();
</script>
